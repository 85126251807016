import { useAuth0 } from "@auth0/auth0-react";
import { lazy } from "@loadable/component";
import { useEffect } from "react";
import { FC } from "react";
import {
  Route,
  RouteProps,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import { LOCAL_STORAGE } from "utils/constant";

export const history = createBrowserHistory();

export enum routesEnum {
  home = "/",
  dashboard = "/dashboard",
  login = "/login",
  kyc = "/kyc",
  accountManagement = "/account",
}

type CustomRouteProps = RouteProps & { private?: boolean };
const routes: CustomRouteProps[] = [
  {
    path: routesEnum.home,
    exact: true,
    component: lazy(() => import("./HomePage")),
  },
  {
    path: routesEnum.kyc,
    exact: true,
    component: lazy(() => import("./IdentifyVerification")),
    private: true,
  },
  {
    path: routesEnum.accountManagement,
    exact: true,
    component: lazy(() => import("./AccountManagementPage")),
    private: true,
  },
  {
    path: routesEnum.dashboard,
    exact: true,
    component: lazy(() => import("./DashBoardPage")),
  },
  {
    path: routesEnum.login,
    exact: true,
    component: lazy(() => import("./LoginPage")),
  },
];

const pathForGuest: string[] = [routesEnum.login];

export const Routes: FC = () => {
  const location = useLocation();
  const history = useHistory();
  const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();

  useEffect(() => {
    if (isLoading) {
      return;
    }
    const route = routes.find(
      (route) => (route.path as string) === location.pathname
    );
    if (
      !isAuthenticated &&
      route?.path !== routesEnum.login &&
      !!route?.private
    ) {
      history.push(routesEnum.login);
    } else if (
      (isAuthenticated && pathForGuest.includes(route?.path as string)) ||
      !route
    ) {
      history.push(routesEnum.accountManagement);
    }
  }, [location, isLoading]);

  const setToken = async () => {
    const token = await getAccessTokenSilently();
    localStorage.setItem(LOCAL_STORAGE.accessToken, token);
  };

  useEffect(() => {
    if (isAuthenticated) {
      setToken();
    }
  }, [isAuthenticated]);

  return (
    <Switch>
      {routes.map((route) => (
        <Route
          path={route.path}
          key={route.path as string}
          exact={route.exact}
          component={route.component}
        />
      ))}
    </Switch>
  );
};
