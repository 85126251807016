export const LOCAL_STORAGE = {
  accessToken: "access-token",
  userInfo: "user-info",
  language: "i18nextLng",
  redirectUrl: "redirect-url",
};

export const LANGUAGE = ["en-US", "tr-TR"];

export const DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN as string;
export const CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID as string;
export const AUDIENCE = process.env.REACT_APP_AUTH0_CLIENT_AUDIENCE as string;
