import ReactDOM from "react-dom";

import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import store from "./store";
import "./i18n";

import { Auth0Provider } from "@auth0/auth0-react";
import { AUDIENCE, CLIENT_ID, DOMAIN } from "utils/constant";

ReactDOM.render(
  <Provider store={store}>
    <HelmetProvider>
      <Auth0Provider
        domain={DOMAIN}
        clientId={CLIENT_ID}
        redirectUri={window.location.origin}
        audience={AUDIENCE}
      >
        <App />
      </Auth0Provider>
    </HelmetProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
