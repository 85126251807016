import { Layout } from "components/Layout";
import { LoadingFullpage } from "components/Loading";
import { Routes } from "pages/Routes";
import { Suspense } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router } from "react-router-dom";
import { useFontObserver } from "hooks";
import { ReactQueryDevtools } from "react-query/devtools";
import "./App.less";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: { refetchOnWindowFocus: false, cacheTime: 0, retry: false },
  },
});

const loadingFullpage = <LoadingFullpage />;

function App() {
  const fontLoaded = useFontObserver();
  return (
    <>
      {!fontLoaded && loadingFullpage}
      <Suspense fallback={loadingFullpage}>
        <QueryClientProvider client={queryClient}>
          <Router>
            <Layout>
              <Routes />
            </Layout>
          </Router>
          {process.env.REACT_APP_REACT_QUERY_DEVTOOLS === "true" && (
            <ReactQueryDevtools initialIsOpen={false} />
          )}
        </QueryClientProvider>
      </Suspense>
    </>
  );
}

export default App;
