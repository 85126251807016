import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "store";
import { LOCAL_STORAGE } from "utils/constant";

export interface TUserReducer {
  accessToken: string;
}

const initialState = {
  accessToken: localStorage.getItem(LOCAL_STORAGE.accessToken) || "",
} as TUserReducer;

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setAccessToken(state, action: PayloadAction<string>) {
      return {
        ...state,
        accessToken: action.payload,
      };
    },
    setUserInfo(state, action: PayloadAction<any>) {
      return {
        ...state,
        userInfo: action.payload,
      };
    },
    setLogout() {
      localStorage.removeItem(LOCAL_STORAGE.accessToken);
      return initialState;
    },
  },
});

export const getUser = (state: RootState): TUserReducer => state.user;

export const { setAccessToken, setUserInfo, setLogout } = userSlice.actions;
export default userSlice.reducer;
