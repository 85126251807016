import { FC } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.css";
import { useAppDispatch, useAppSelector } from "hooks";
import { setLanguage, setLanguageType } from "store/ducks/system/slice";
import { LANGUAGE } from "utils/constant";
import { Menu } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { Dropdown } from "components/Dropdown";

const normalizeLangCode = (langCode: string) =>
  langCode?.split("-")[0].toUpperCase();

export const LanguageSelect: FC = () => {
  const { i18n } = useTranslation();
  const dispatch = useAppDispatch();

  const { language } = useAppSelector((state) => state.system);

  const onChangeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    dispatch(setLanguage(language));
    dispatch(setLanguageType());
  };

  const languageMenu = (
    <Menu>
      {LANGUAGE.map((language) => (
        <Menu.Item key={language} onClick={() => onChangeLanguage(language)}>
          {normalizeLangCode(language)}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={languageMenu}>
      <p className="mb-0 cursor">
        {normalizeLangCode(language)}
        <FontAwesomeIcon className={styles.downOutline} icon={faChevronDown} />
      </p>
    </Dropdown>
  );
};
