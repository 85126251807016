import { FC } from "react";
import styles from "./NavBar.module.css";
import { Layout, Menu, Skeleton, Space } from "antd";
import { Button as ButtonAntd } from "antd";
import clsx from "clsx";
import { TFunction, useTranslation } from "react-i18next";
import {
  faUser,
  faArrowRight,
  faPowerOff,
  faThLarge,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useHistory } from "react-router-dom";
import { Button } from "components/Button";
import { routesEnum } from "pages/Routes";
import { Dropdown } from "components/Dropdown";
import { useAppDispatch } from "hooks";
import { useMemo } from "react";
import { History } from "history";
import { LanguageSelect } from "components/LanguageSelect";
import { useAuth0, User } from "@auth0/auth0-react";
import { setLogout } from "store/ducks/user/slice";

const accountRoutes = [
  {
    title: "navbar.account.account_management",
    path: routesEnum.accountManagement,
    icon: faThLarge,
  },
];

const getAccountMenu = (
  t: TFunction<"translation">,
  logout: () => void,
  history: History,
  dispatch: Function,
  user?: User
) => (
  <Menu>
    <Menu.Item
      key={user?.email}
      icon={<FontAwesomeIcon icon={faArrowRight} />}
      onClick={() => history.push(routesEnum.accountManagement)}
    >
      {user?.email}
    </Menu.Item>
    <Menu.Divider />
    {accountRoutes.map((account) => (
      <Menu.Item
        key={account.title}
        icon={<FontAwesomeIcon icon={account.icon} />}
        onClick={() => history.push(account.path)}
      >
        {t(account.title)}
      </Menu.Item>
    ))}
    <Menu.Item
      key="navbar.account.log_out"
      icon={<FontAwesomeIcon icon={faPowerOff} />}
      onClick={() => handleLogout(logout, dispatch)}
    >
      {t("navbar.account.log_out")}
    </Menu.Item>
  </Menu>
);

const handleLogout = (logout: Function, dispatch: Function) => {
  dispatch(setLogout());
  logout({ returnTo: window.location.origin });
};

export const NavBar: FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { isAuthenticated, user, loginWithRedirect, logout, isLoading } =
    useAuth0();

  const accountMenu = useMemo(
    () => getAccountMenu(t, logout, history, dispatch, user),
    [t, user, logout, history]
  );

  return (
    <Layout.Header className={styles.root}>
      <div className={clsx("container", styles.content)}>
        <Link to={routesEnum.login}>
          <span className={styles.textLogo}>TBONE</span>
        </Link>
        <Menu mode="horizontal" className={styles.menu}></Menu>
        <div className={styles.rightMenu}>
          <Space align="center" className={styles.buttons}>
            {isLoading ? (
              <Skeleton.Button active shape="round" style={{ width: 200 }} />
            ) : isAuthenticated ? (
              <>
                <Dropdown
                  overlayClassName={styles.accountDropdown}
                  overlay={accountMenu}
                >
                  <div className={styles.accountButton}>
                    <ButtonAntd
                      shape="circle"
                      icon={<FontAwesomeIcon icon={faUser} color="#fff" />}
                      size="middle"
                    />
                  </div>
                </Dropdown>
              </>
            ) : (
              <>
                <Button onClick={() => loginWithRedirect()}>
                  {t("navbar.nav.log_in")}
                </Button>
                <Button
                  type="primary"
                  onClick={() => loginWithRedirect({ screen_hint: "signUp" })}
                >
                  {t("navbar.nav.register")}
                </Button>
              </>
            )}
            <LanguageSelect />
          </Space>
        </div>
      </div>
    </Layout.Header>
  );
};
