import { FC, useMemo } from "react";
import styles from "./Layout.module.css";
import { Layout as AntdLayout } from "antd";
import { Footer } from "./Footer";
import { NavBar } from "./NavBar";
import { ConfigProvider as AntdConfigProvider } from "antd";
import { TFunction, useTranslation } from "react-i18next";

const getValidateMessages = (t: TFunction<"translation">) => ({
  required: t("form.validate.required"),
});

export const Layout: FC = ({ children }) => {
  const { t } = useTranslation();

  const validateMessages = useMemo(() => getValidateMessages(t), [t]);

  return (
    <AntdConfigProvider
      form={{
        validateMessages,
      }}
    >
      <AntdLayout className={styles.root}>
        <NavBar />
        {/* <div className={styles.bg}>
          <div className={styles.circle1} />
          <div className={styles.circle2} />
        </div> */}
        <main>{children}</main>
        <Footer />
      </AntdLayout>
    </AntdConfigProvider>
  );
};
