import { FC } from "react";
import styles from "./Footer.module.css";
import clsx from "clsx";

import { Col, Layout as AntdLayout, Row } from "antd";
import { Link } from "react-router-dom";
import Logo from "assets/images/logo.svg";

export const Footer: FC = () => {
  return (
    <AntdLayout.Footer className={styles.root}>
      <div className={clsx(styles.content, "container")}>
        <Row
          wrap={false}
          gutter={49.5}
          justify="space-between"
          className={styles.row}
        >
          <Col>
            <Link to="/">
              <img src={Logo} />
            </Link>
          </Col>
          <Col className={styles.colCopyRight}>
            <div className={clsx("secondary")}>
              © 2021 ULTOREX, All Rights Reserved.
            </div>
          </Col>
        </Row>
      </div>
    </AntdLayout.Footer>
  );
};
